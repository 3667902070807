import React, { Component } from "react";
import Page from "../components/Page";
import Container from "../components/styles/Container";
import Text from "../components/styles/Text";

class garage extends Component {
  render() {
    return (
      <div>
        <Page>
          <Container>
            <div
              dangerouslySetInnerHTML={{
                __html: `
                <div class="et_pb_text_inner">
                <h1>Talk to our International Sales Team</h1>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_1">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_1 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_1 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_0 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">China Distribution</h4>
                  <div class="et_pb_blurb_description">
                    <p style="font-weight: 400;">US Trading (Tianjin) Co., Ltd.</p>
      <p style="font-weight: 400;">Contact: Xinyan Li<br>Email: <a href="mailto:xinyan.us@outlook.com">xinyan.us@outlook.com</a><br>Phone: 022-59000304</p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_2 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_2 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_1 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">All Distribution outside of U.S. and China</h4>
                  <div class="et_pb_blurb_description">
                    <p>
      Contact: Kim Carter</p>
      <p>Email: <a href="mailto:kidsinternationalmarketing@gmail.com" target="_blank" rel="noopener noreferrer">kidsinternationalmarket<wbr>ing@gmail.com</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_2">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_3 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_3 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_2 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Australia </h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">KidzWiz</p>
      <p class="p1">Contact: Meyer Diamond</p>
      <p>Email: <a href="mailto:meyer@kidzwiz.cool">meyer@kidzwiz.cool</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_4 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_4 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_3 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Austria </h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">JH Products</p>
      <p>Contact: Jochen Heil</p>
      <p>Email: <a href="mailto:Jochen@folkmanis.de">Jochen@folkmanis.de</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_3">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_5 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_5 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_4 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Bahrain </h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">Oriental Elite</p>
      <p class="p1">Contact: Mohammed Vassem</p>
      <p>Email: <a href="mailto:orelite@eim.ae">orelite@eim.ae </a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_6 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_6 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_5 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Belgium</h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">JH Products</p>
      <p>Contact: Jochen Heil</p>
      <p>Email: <a href="mailto:Jochen@folkmanis.de">Jochen@folkmanis.de</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_4">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_7 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_7 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_6 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Canada</h4>
                  <div class="et_pb_blurb_description">
                    <p>Contact: Jeffrey Gruia</p>
      <p>Email: <b><a href="mailto:jeffrey@iambrambo.com" target="_blank" rel="noopener noreferrer">jeffrey@iambrambo.com</a></b></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_8 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_8 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_7 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Chile</h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">ABOC</p>
      <p>Contact: Ana Cortazar</p>
      <p>Email: <a class="waffle-hyperlink-tooltip-link" href="mailto:anacortazar@aboc.cl" target="_blank" rel="noopener noreferrer">anacortazar@aboc.cl</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_5">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_9 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_9 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_8 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Croatia </h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">Mundolino</p>
      <p class="p1">Contact: Spela Bricelj</p>
      <p>Email: <a href="mailto:mundolino.toys@gmail.com">mundolino.toys@gmail.com</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_10 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_10 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_9 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Costa Rica</h4>
                  <div class="et_pb_blurb_description">
                    <p>Latam Distribuidora</p>
      <p>Contact: Becky Buzaglu</p>
      <p>Email: <a href="mailto:gerencia@latamdist.com">gerencia@latamdist.com</a><b></b></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_6">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_11 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_11 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_10 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Czech Republic</h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">JH Products</p>
      <p>Contact: Jochen Heil</p>
      <p>Email: <a href="mailto:Jochen@folkmanis.de">Jochen@folkmanis.de</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_12 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_12 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_11 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Denmark</h4>
                  <div class="et_pb_blurb_description">
                    <p>TOY Intelligence &amp; Quality Sweden</p>
      <p>Contact: Thomas Möller</p>
      <p>Email: <a href="mailto:thomas.moller@toyiq.se">thomas.moller@toyiq.se</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_7">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_13 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_13 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_12 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Estonia</h4>
                  <div class="et_pb_blurb_description">
                    <p>Muki LV</p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_14 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_14 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_13 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Finland</h4>
                  <div class="et_pb_blurb_description">
                    <p>TOY Intelligence &amp; Quality Sweden</p>
      <p>Contact: Thomas Möller</p>
      <p>Email: <a href="mailto:thomas.moller@toyiq.se">thomas.moller@toyiq.se</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_8">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_15 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_15 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_14 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">France</h4>
                  <div class="et_pb_blurb_description">

                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_16 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_16 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_15 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Germany </h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">JH Products</p>
      <p class="p1">Contact: Jochen Heil</p>
      <p>Email: <a href="mailto:Jochen@folkmanis.de">Jochen@folkmanis.de</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_9">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_17 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_17 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_16 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Guyana</h4>
                  <div class="et_pb_blurb_description">
                    <p>
      Austins Bookstores</p>
      <p>Contact: Lloyd Austin</p>
      <p>Email: <a href="mailto:austins@guyana.gy">austins@guyana.gy</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_18 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_18 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_17 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Guatemala</h4>
                  <div class="et_pb_blurb_description">
                    <p>Latam Distribuidora</p>
      <p>Contact: Becky Buzaglu</p>
      <p>Email: <a href="mailto:gerencia@latamdist.com">gerencia@latamdist.com</a></p>
                  </div>
                </div>
              </div>
            </div> <div class="et_pb_module et_pb_blurb et_pb_blurb_18 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Hong Kong</h4>
                  <div class="et_pb_blurb_description">
                    <p style="font-weight: 400;">US Trading (Tianjin) Co., Ltd.</p>
      <p style="font-weight: 400;">Contact: Xinyan Li<br>Email: <a href="mailto:xinyan.us@outlook.com">xinyan.us@outlook.com</a><br>Phone: 022-59000304</p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_10">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_19 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_19 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_19 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Hungary</h4>
                  <div class="et_pb_blurb_description">
                    <p>Curiocity</p>
      <p>Contact: Ferenc Gergely </p>
      <p>Email: <a href="mailto:mail@curiocity.hu">mail@curiocity.hu</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_20 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_20 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_20 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">India </h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">Simon Trading</p>
      <p class="p1">Contact: Faheem Iqbal</p>
      <p>Email: <a href="mailto:info@simontoys.com">info@simontoys.com</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_11">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_21 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_21 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_21 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Ireland </h4>
                  <div class="et_pb_blurb_description">
                    <p> TKC Sales Ltd</p>
      <p>Contact: Henry Turner</p>
      <p>Email: <a href="mailto:sales@tkcsales.co.uk">sales@tkcsales.co.uk</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_22 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_22 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_22 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Israel</h4>
                  <div class="et_pb_blurb_description">
                    <p>Marmelada</p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_12">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_23 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_23 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_23 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Italy</h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">Borella srl</p>
      <p class="p1">Contact: Claudio Borella</p>
      <p>Email: <a href="mailto:c.borella@orsomago.it">c.borella@orsomago.it</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_24 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_24 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_24 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Japan</h4>
                  <div class="et_pb_blurb_description">
                    <p>Dream Blossom</p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_13">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_25 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_25 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_25 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Kuwait</h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">Oriental Elite</p>
      <p class="p1">Contact: Mohammed Vassem</p>
      <p>Email: <a href="mailto:orelite@eim.ae">orelite@eim.ae</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_26 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_26 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_26 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Latvia</h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">Muki</p>
      <p class="p1">Contact: Iveta Sondore</p>
      <p>Email: <a href="mailto:iveta@muki.lv">iveta@muki.lv</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_14">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_27 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_27 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_27 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Mexico</h4>
                  <div class="et_pb_blurb_description">
                    <p>Impulsora Euro</p>
      <p>Contact: Rosy Hernandez</p>
      <p>Email: <a href="mailto:autoservicio@impulsoraeuro.com">autoservicio@impulsoraeuro.com</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_28 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_28 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_28 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Netherlands</h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">JH Products</p>
      <p class="p1">Contact: Jochen Heil</p>
      <p>Email: <a href="mailto:Jochen@folkmanis.de">Jochen@folkmanis.de</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_15">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_29 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_29 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_29 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">New Zealand</h4>
                  <div class="et_pb_blurb_description">
                    <p> Logical Toys</p>
      <p>Contact: Mark Heyman</p>
      <p>Email: <a href="mailto:mark@logicaltoys.co.nz" target="_blank" rel="noopener noreferrer">mark@logicaltoys.co.nz</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_30 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_30 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_30 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Norway</h4>
                  <div class="et_pb_blurb_description">
                    <p>Toy Intelligence &amp; Quality Sweden</p>
      <p>Thomas Möller</p>
      <p>Contact: <a href="mailto:thomas.moller@toyiq.se">thomas.moller@toyiq.se</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_16">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_31 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_31 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_31 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Oman</h4>
                  <div class="et_pb_blurb_description">
                    <p>
      Oriental Elite</p>
      <p>Contact: Mohammed Vassem</p>
      <p>Email: <a href="mailto:orelite@eim.ae">orelite@eim.ae</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_32 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_32 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_32 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Panama</h4>
                  <div class="et_pb_blurb_description">
                    <p>Latam Distribuidora</p>
      <p class="p1">Contact: Becky Buzaglu</p>
      <p class="p1">Email: <a href="mailto:gerencia@latamdist.com" target="_blank" rel="noopener noreferrer">gerencia@latamdist.com</a></p>
                  </div>
                </div>
              </div>
            </div> <div class="et_pb_module et_pb_blurb et_pb_blurb_33 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Philippines</h4>
                  <div class="et_pb_blurb_description">
                    <p>
      Perfect Home</p>
      <p class="p1">Contact: Joanne Uy</p>
      <p class="p1">Email:<a href="mailto:joanne@perfecthomemanila.com"> joanne@perfecthomemanila.com</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_17">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_33 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_33 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_34 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Poland</h4>
                  <div class="et_pb_blurb_description">
                    <p>Q Elements</p>
      <p>Contact: Magda Mazur-Chang</p>
      <p>Email: <a href="mailto:magdamazur@gmail.com">magdamazur@gmail.com</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_34 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_34 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_35 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Portugal</h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">JH Products</p>
      <p class="p1">Contact: Jochen Heil</p>
      <p>Email: <a href="mailto:Jochen@folkmanis.de">Jochen@folkmanis.de</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_18">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_35 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_35 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_36 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Qatar</h4>
                  <div class="et_pb_blurb_description">
                    <p>
      Oriental Elite</p>
      <p class="p1">Contact: Mohammed Vassem</p>
      <p class="p1">Email: <a href="mailto:orelite@eim.ae">orelite@eim.ae</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_36 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_36 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div>
            </div>


            </div> <div class="et_pb_row et_pb_row_19">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_37 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_37 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_37 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Romania</h4>
                  <div class="et_pb_blurb_description">
                    <p>
      Piccolino</p>
      <p>Contact: Alexandrina strungaru</p>
      <p>Email: <a href="mailto:Alexandrina.strungaru@piccolino.ro">Alexandrina.strungaru@piccolino.ro</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_38 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_38 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_38 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Russia</h4>
                  <div class="et_pb_blurb_description">
                    <p>
      SPL-Technik</p>
      <p class="p1">Contact: Oleg Soglaev</p>
      <p class="p1">Email:<a href="mailto:osoglaev@spl-technik.de"> osoglaev@spl-technik.de</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_20">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_39 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_39 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_39 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Serbia</h4>
                  <div class="et_pb_blurb_description">
                    <p>D2plus Svetovanje d.o.o.</p>
      <p class="p1">Contact: Spela Bricelj</p>
      <p class="p1">Email: <a href="mailto:info@mundolino.si">info@mundolino.si</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_40 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_40 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_40 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Singapore</h4>
                  <div class="et_pb_blurb_description">
                    <p>Play Hao</p>
      <p class="p1">Contact: Mr TL Tan</p>
      <p>www.playhao.com</p>
      <p class="p1">Email: play@playhao.com</p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_21">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_41 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_41 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_41 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Slovakia</h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">JH Products</p>
      <p class="p1">Contact: Jochen Heil</p>
      <p>Email: <a href="mailto:Jochen@folkmanis.de">Jochen@folkmanis.de</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_42 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_42 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_42 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Slovenia</h4>
                  <div class="et_pb_blurb_description">
                    <p>Mundolino</p>
      <p>Contact: Spela Bricelj</p>
      <p>www.mundolino.si</p>
      <p>Email: <a href="mailto:mundolino.toys@gmail.com">mundolino.toys@gmail.com</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_22">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_43 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_43 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_43 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">South Africa</h4>
                  <div class="et_pb_blurb_description">

                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_44 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_44 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_44 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">South Korea </h4>
                  <div class="et_pb_blurb_description">

                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_23">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_45 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_45 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_45 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Spain</h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">JH Products</p>
      <p class="p1">Contact: Jochen Heil</p>
      <p>Email: <a href="mailto:Jochen@folkmanis.de">Jochen@folkmanis.de</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_46 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_46 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_46 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Sweden</h4>
                  <div class="et_pb_blurb_description">
                    <p>Toy Intelligence &amp; Quality Sweden</p>
      <p>Thomas Möller</p>
      <p>Contact: <a href="mailto:thomas.moller@toyiq.se">thomas.moller@toyiq.se</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_24">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_47 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_47 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_47 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Switzerland</h4>
                  <div class="et_pb_blurb_description">
                    <p>JH Products</p>
      <p class="p1">Contact: Jochen Heil</p>
      <p class="p1">Email: <a href="mailto:Jochen@folkmanis.de">Jochen@folkmanis.de</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_48 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_48 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_48 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">UAE</h4>
                  <div class="et_pb_blurb_description">
                    <p>
      Oriental Elite</p>
      <p>Contact: Mohammed Vassem</p>
      <p>Email: <a href="mailto:orelite@eim.ae">orelite@eim.ae</a></p>
                  </div>
                </div>
              </div>
            </div>
            </div>


            </div> <div class="et_pb_row et_pb_row_25">
              <div class="et_pb_column et_pb_column_1_2 et_pb_column_49 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_49 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_49 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">UK</h4>
                  <div class="et_pb_blurb_description">
                    <p>TKC Sales Ltd.</p>
      <p class="p1">Contact: Henry Turner</p>
      <p class="p1">Email: <a href="mailto:sales@tkcsales.co.uk">sales@tkcsales.co.uk</a></p>
                  </div>
                </div>
              </div>
            </div> <div class="et_pb_module et_pb_divider et_pb_divider_50 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div>
            </div> <div class="et_pb_column et_pb_column_1_2 et_pb_column_50 et_pb_css_mix_blend_mode_passthrough">


              <div class="et_pb_module et_pb_divider et_pb_divider_51 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_blurb et_pb_blurb_50 et_pb_bg_layout_light et_pb_text_align_left et_pb_blurb_position_top">


              <div class="et_pb_blurb_content">

                <div class="et_pb_blurb_container">
                  <h4 class="et_pb_module_header">Ukraine</h4>
                  <div class="et_pb_blurb_description">
                    <p class="p1">Vanopl Inc. </p>
      <p class="p1">Contact: Ivan Plotnikov</p>
      <p>Email: <a href="mailto:IP@vanopl.com">IP@vanopl.com</a></p>
                  </div>
                </div>
              </div> `
              }}
            />
          </Container>
        </Page>
      </div>
    );
  }
}

export default garage;
